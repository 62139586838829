@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: Inter;
        font-weight: 100 900;
        font-style: normal;
        font-named-instance: Regular;
        src: url('./fonts/Inter-roman.var.woff2') format('woff2');
        font-display: swap;
    }
    @font-face {
        font-family: Inter;
        font-weight: 100 900;
        font-style: italic;
        font-named-instance: Italic;
        src: url('./fonts/Inter-italic.var.woff2') format('woff2');
        font-display: swap;
    }
    @font-face {
        font-family: 'Value Serif';
        font-weight: bold;
        font-style: normal;
        src: url('./fonts/value-serif-bold-pro.woff2') format('woff2');
        font-display: swap;
    }

    input {
        /* Reset the border radius on form elements to be consistent across browsers (iOS Safari rounds them by default). More info: https:/github.com/tailwindlabs/tailwindcss/pull/758 */
        border-radius: 0;
        /* Fix box-shadow not being applied to inputs in iOS Safari. It's actually -webkit-appearance but the autoprefixer does that for us. */
        appearance: none;
    }

    /* Reset font-weight of buttons to be consistent across browsers (iOS Safari sets it to semibold). */
    button {
        font-weight: inherit;
    }

    body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        word-break: break-word;
        /* bg-white: Needed for backdrop-filter of NavBar */
        @apply text-grey-700 bg-white;
    }

    /* Enable percentage-based heights. More info: https://www.joshwcomeau.com/css/custom-css-reset/#digit-percentage-based-heights */
    html,
    body,
    #__next {
        height: 100%;
    }

    /* Prevent z-index in app intervening with modals. More info: https://www.joshwcomeau.com/css/custom-css-reset/#digit-root-stacking-context */
    #__next {
        isolation: isolate;
    }

    /**
     * We want our website elements to grow automatically based on the browser width.
     * Therefore we use rems to define element sizes and vary the px value of 1rem for different browser widths:
     * - Up to 767px browser width: 18px
     * - 768-1279px browser width: 20px, possibly fluid value
     * - 1280px browser width and up: 22px
     */
    :root {
        font-size: 18px;
    }
    @screen md {
        :root {
            font-size: 20px;
        }
    }
    @screen xl {
        :root {
            font-size: 22px;
        }
    }

    /**
     * Fluid transition between 18px and 22px as per Nick's design spec.
     * - 1rem = 18px for up to 319px browser width
     * - 1rem = 18-22px (scaling linearly) for 320-1814px browser width
     * - 1rem = 22px for 1815px browser width and up
     * It's disabled for now because we are not sure whether we want to enable this globally.
     */
    /* @media (min-width: 320px) {
        :root {
            font-size: calc((100vw - 320px) / (1815 - 320) * (22 - 18) + 18px);
        }
    }
    @media (min-width: 1815) {
        :root {
            font-size: 22px;
        }
    } */
}

@layer utilities {
    /* More info: https://github.com/tailwindlabs/tailwindcss/discussions/2394 */
    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }
    /* Firefox */
    .no-scrollbar {
        scrollbar-width: none;
    }
}
